import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import deepmerge from '@mui/utils/deepmerge';
import classNames from 'classnames';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components;
    return deepmerge({
        root: {
            display: 'inline-block',
            height: '1.1em',
            width: '.9em',
            marginRight: '.3em',
            backgroundColor: 'currentColor',
            maskSize: 'contain',
            lineHeight: 'inherit',
            verticalAlign: 'text-top',
            //   width: currencyIcon.width,
            //   height: currencyIcon.height,
            //   mask: `url("${currencyIcon.url}") no-repeat left`,
        },
    }, (_b = components.CxmCurrencyIcon) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
export default function CurrencyIcon(props) {
    var _a;
    var theme = useTheme();
    var _b = ((_a = theme.components.CxmCurrencyIcon) === null || _a === void 0 ? void 0 : _a.defaultProps) || {}, isAsset = _b.isAsset, _c = _b.iconName, iconName = _c === void 0 ? 'fa-coins' : _c;
    var classes = useStyles();
    if (isAsset) {
        // asset icon
        return _jsx("span", { className: classNames(classes.root, props.className), title: "Currency" });
    }
    else {
        return _jsx(Icon, { className: classNames(iconName, classes.root, props.className) });
    }
}
