import LinearProgress from '@mui/material/LinearProgress'
import { Suspense, lazy } from 'react'

import { PageTemplates } from 'jticxm-constants'

import sharedTemplateFactory from 'shared/core/templateFactory'

const PageCamelDashboard = lazy(() => import('camel-de/src/templates/PageCamelDashboard'))
const PageCamelProfile = lazy(() => import('camel-de/src/templates/PageProfile'))
const PageCamelDropIt = lazy(() => import('camel-de/src/templates/PageDropIt'))
const PageCamelPinata = lazy(() => import('camel-de/src/templates/PagePinata'))
const PageCamelWin = lazy(() => import('camel-de/src/templates/PageWin'))
const PageCamelFAQ = lazy(() => import('camel-de/src/templates/PageFAQ'))
const PageCamelTerms = lazy(() => import('camel-de/src/templates/PageTerms'))
const PageCamelWelcome = lazy(() => import('camel-de/src/templates/PageWelcome'))
const PageCamelRadio = lazy(() => import('camel-de/src/templates/PageRadio'))
const PageCamelLoyaltySystem = lazy(() => import('camel-de/src/templates/PageLoyaltySystem'))
const PageCamelGiveaway = lazy(() => import('camel-de/src/templates/PageGiveaway'))
const PopupCamelSurvey = lazy(() => import('camel-de/src/templates/PopupSurvey'))
const PageCamelContentHub = lazy(() => import('camel-de/src/templates/PageContentHub'))
const PageCamelContentArticle = lazy(() => import('camel-de/src/templates/PageContentArticle'))
const PageCamelRanking = lazy(() => import('camel-de/src/templates/promotions/PageRanking'))
const PageCamelInviteMember = lazy(() => import('camel-de/src/templates/PageInviteMember'))

export default function templateFactory(state): JSX.Element {
  const { component } = state || {}
  if (!component) return null

  // camel templates
  switch (component) {
    case PageTemplates.PT_CAMEL_DASHBOARD:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelDashboard {...state} />
        </Suspense>
      )
    case PageTemplates.PT_PROFILE:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelProfile {...state} />
        </Suspense>
      )
    case PageTemplates.PT_CAMEL_DROPIT:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelDropIt {...state} />
        </Suspense>
      )
    case PageTemplates.PT_CAMEL_PINATA:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelPinata {...state} />
        </Suspense>
      )
    case PageTemplates.PT_WIN:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelWin {...state} />
        </Suspense>
      )
    case PageTemplates.PT_FAQ:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelFAQ {...state} />
        </Suspense>
      )
    case PageTemplates.PT_TERMS:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelTerms {...state} />
        </Suspense>
      )
    case PageTemplates.PT_WELCOME:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelWelcome {...state} />
        </Suspense>
      )
    case PageTemplates.PT_RADIO:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelRadio {...state} />
        </Suspense>
      )
    case PageTemplates.PT_LOYALTY_SYSTEM:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelLoyaltySystem {...state} />
        </Suspense>
      )
    case PageTemplates.PT_GIVEAWAY:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelGiveaway {...state} />
        </Suspense>
      )
    case PageTemplates.PP_SURVEY:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PopupCamelSurvey {...state} />
        </Suspense>
      )
    case PageTemplates.PT_CONTENT_HUB:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelContentHub key={state.page.pageId} {...state} />
        </Suspense>
      )
    case PageTemplates.PT_CONTENT_ARTICLE:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelContentArticle {...state} />
        </Suspense>
      )
    case PageTemplates.PT_RANKING:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelRanking {...state} />
        </Suspense>
      )
    case PageTemplates.PT_INVITE_MEMBER:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageCamelInviteMember {...state} />
        </Suspense>
      )
  }

  // shared templates
  return sharedTemplateFactory(state)
}
