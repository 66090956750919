"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_RANGES = exports.CRM_PROMOTION_TYPES = exports.SIGNUP_STEPS = exports.AUDIT_TIME_FRAMES = exports.SYS_CAMPAIGN_ACTION = exports.SYS_LOG_STATUS = exports.NOTIF_REMINDER = exports.PROCESSOR = exports.ACCESS_CONTROL_TYPES = exports.LIKE_ITEM_TYPES = exports.UPLOAD_FILE_TYPES = void 0;
exports.UPLOAD_FILE_TYPES = {
    IMAGE: 'image/png, image/jpeg, image/webp',
    VIDEO: 'video/mp4, video/quicktime, video/webm, video/x-ms-wmv',
    AUDIO: 'audio/mp3, audio/mpeg, audio/x-wav',
};
exports.LIKE_ITEM_TYPES = {
    ARTICLE: 'article',
    BUNDLE: 'bundle',
    STORY_AD: 'story-ad',
    UPLOAD: 'upload',
};
exports.ACCESS_CONTROL_TYPES = {
    RESTRICTED: 'restricted',
    PUBLIC: 'public',
    PUBLIC_ONLY: 'publiconly',
};
exports.PROCESSOR = {
    WINSTON_JAM: 'winston-jam',
    WINSTON_JAM_COVER: 'winston-jam-cover',
};
exports.NOTIF_REMINDER = {
    BIRTHDAY: 'birthday',
    COMEBACK: 'comeback',
    DELETION_IN_FOUR_WEEKS: 'deletionInFourWeeks',
    DELETION_IN_ONE_WEEK: 'deletionInOneWeek',
    EMAIL_VALIDATION_FIRST_REMINDER: 'emailValidationFirstReminder',
    EMAIL_VALIDATION_SECOND_REMINDER: 'emailValidationSecondReminder',
    POINTS_EXPIRING_IN_WEEKS: 'pointsExpiringInWeeks',
    REGISTRATION_ANNIVERSARY: 'registrationAnniversary',
    XMAS_REMINDER: 'xmasReminder',
};
exports.SYS_LOG_STATUS = {
    SUCCESS: 'approved',
    ERROR: 'cancelled',
};
exports.SYS_CAMPAIGN_ACTION = {
    SENT: 'sent',
    FAILED: 'failed',
    DELIVERED: 'delivered',
    OPENED: 'opened',
    CLICKED: 'clicked',
    LIKED: 'liked',
    DISLIKED: 'disliked',
};
exports.AUDIT_TIME_FRAMES = {
    DAILY: 'daily',
    EACH_TEN_MINUTES: 'eachTenMinutes',
    NIGHT_TIME: 'nightTime',
};
exports.SIGNUP_STEPS = {
    ADD_BRAND: 'addBrand',
    ADD_BRAND_CREDENTIALS: 'addBrandCredentials',
    ADD_BRAND_REGISTER_SUCCESS: 'addBrandRegisterSuccess',
    AGE_VERIFICATION: 'ageVerification',
    AGE_VERIFICATION_CODE: 'ageVerificationCode',
    AGE_VERIFICATION_FAILED: 'ageVerificationFailed',
    AGE_VERIFICATION_OPTIONS: 'ageVerificationOptions',
    AGE_VERIFICATION_SCHUFA: 'ageVerificationSchufa',
    AGE_VERIFICATION_VERIFF: 'ageVerificationVeriff',
    CREDENTIALS: 'credentials',
    INITIAL: 'initial',
    LOGIN: 'login',
    REGISTER_SUCCESS: 'registerSuccess',
    REGISTER_VIA_PROMOTER_SUCCESS: 'registerViaPromoterSuccess',
    SIGNUP: 'signup',
};
exports.CRM_PROMOTION_TYPES = {
    B2B2C: 'b2b2c',
    ONE_TWO_ONE: '1-2-1',
    RAFFLE: 'raffle',
};
exports.PRODUCT_RANGES = {
    ALL: '',
    COMBUSTIBLES: 'combustibles',
    PLOOM: 'ploom',
};
