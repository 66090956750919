var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useApi } from 'shared/api/ApiContext';
import Button from 'shared/components/ContentModule/Button';
import VideoPlayer from 'shared/components/VideoPlayer';
import Media from 'shared/elements/Media';
import getDeepModuleLink from 'shared/utils/getDeepModuleLink';
import StoryAdsLike from './StoryAdsLike';
export default function StoryAdsSlides(props) {
    var navigate = useNavigate();
    var ads = props.ads, _a = props.classes, classes = _a === void 0 ? {} : _a, currentAdIndex = props.currentAdIndex, close = props.close, muted = props.muted, points = props.points, paused = props.paused;
    var api = useApi();
    var handleLinkClick = function (e, ad) {
        e.preventDefault();
        var _a = ad.button, buttonExternalLink = _a.buttonExternalLink, buttonDeepLink = _a.buttonDeepLink, buttonLink = _a.buttonLink, _id = ad._id;
        api.trackStoryAdClick(_id);
        close();
        if (buttonExternalLink) {
            setTimeout(function () {
                window.location.href = buttonExternalLink;
            }, 500);
            return;
        }
        var link = buttonDeepLink ? getDeepModuleLink(buttonDeepLink) : buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.fullSlug;
        setTimeout(function () {
            navigate(link);
        }, 500);
    };
    return (_jsx(_Fragment, { children: ads.map(function (ad, index) {
            var _a, _b;
            var isVideo = ((_b = (_a = ad.media) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.split('/')[0]) === 'video';
            var adStyle = null;
            if (currentAdIndex > index)
                adStyle = { left: '-100%' };
            if (currentAdIndex < index)
                adStyle = { left: '100%' };
            return (_jsxs("div", __assign({ className: classes.root, style: adStyle }, { children: [isVideo ? (_jsx(VideoPlayer, { media: ad.media, className: classes.media, classes: { video: classes.video, muteButton: classes.muteButton }, autoplay: true, loop: true, hidePlay: true, noAudio: muted || currentAdIndex !== index, noFullsreen: true, paused: paused }, ad._id)) : (_jsx(Media, { className: classes.media, src: ad.media, cover: true, size: 430 }, ad._id)), _jsxs("nav", __assign({ className: classes.buttonsWrapper }, { children: [ad.button && (_jsx(Button, __assign({}, ad.button, { points: points, classes: { contentModuleWrapper: classes.linkButton }, onClick: function (e) { return handleLinkClick(e, ad); } }))), _jsx(StoryAdsLike, { oid: ad._id, hasLike: ad.hasLike, className: classes.likeButton })] }))] }), ad._id));
        }) }));
}
